<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('PARTNERDISCOUNT.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PARTNERDISCOUNT.HEADER.TEXT')}}</span>
        </p>
        <AddPartnerDiscountDlg ref="addPartnerDiscountDlg" v-on:Success="LoadData"></AddPartnerDiscountDlg>
      </vs-card>
    </vs-col>
  </vs-row>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddPartnerDiscount">{{$t('PARTNERDISCOUNT.ADD')}}</vs-button>
            </div> 
            <div id="partnerdiscounts_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="partnerdiscounts"
                :noDataText="$t('PARTNERDISCOUNT.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PARTNERDISCOUNT.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="companygroup_fk">
                    {{$t('PARTNERDISCOUNT.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="productgroup_fk">
                    {{$t('PARTNERDISCOUNT.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="partner_discount_value">
                    {{$t('PARTNERDISCOUNT.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="amountGreater">
                    {{$t('PARTNERDISCOUNT.TABLE.COL4')}}
                  </vs-th>                                                                                                                         
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                    <vs-td :data="tr.companygroup_fk">
                      {{GetCompanyGroupText(tr.companygroup_fk)}}
                    </vs-td>                  
                    <vs-td :data="tr.text">
                      {{GetNameOfProductgroup(tr.productgroup_fk)}}
                    </vs-td>     
                    <vs-td :data="tr.partner_discount_value">
                      {{tr.partner_discount_value}}%
                    </vs-td> 
                    <vs-td :data="tr.amountGreater">
                      {{tr.amountGreater}}
                    </vs-td>                                                                                                                  
                    <vs-td>
                      <vs-button  @click="EditPartnerDiscount(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeletePartnerDiscount(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddPartnerDiscountDlg from './components/dialogs/AddPartnerDiscountDlg.vue';

export default {
  name: "PartnerDiscounts",
  components: {
    AddPartnerDiscountDlg
  },
    data: function (){
      return {
      partnerdiscounts:[]
      };
  },
  mounted () {
      this.$store.dispatch('companygroup/getCompanyGroups', { root: true },{ root: true });
      this.$store.dispatch('productgroup/getProductgroups', { root: true },{ root: true }); 
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.partnerdiscount.partnerdiscounts.data != null)
      {
        data = this.$store.state.partnerdiscount.partnerdiscounts.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.partnerdiscount.partnerdiscounts.status) != 'undefined')
        loading = this.$store.state.partnerdiscount.partnerdiscounts.status.loading;
      return loading;
    },

    languages(){
      return this.GetListOfLanguagesForSelect();
    },
    DocTypes(){
      return this.GetListOfDokumentTypeForSelect();
    }
  },
  watch: {
      getTableData(value) {
          this.partnerdiscounts = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#partnerdiscounts_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#partnerdiscounts_table'});
        }
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('partnerdiscount/getPartnerdiscounts', { root: true },{ root: true }); 
      },
      EditPartnerDiscount(data){
        this.$refs.addPartnerDiscountDlg.InitDlgData(data);
      },
      AddPartnerDiscount(){
        this.$refs.addPartnerDiscountDlg.ShowDlg();
      },  
      OpenConfirmDeletePartnerDiscount(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PARTNERDISCOUNT.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('partnerdiscount/deletePartnerdiscount', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERDISCOUNT.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('PARTNERDISCOUNT.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERDISCOUNT.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      GetCompanyGroupText(id){
        var name = this.GetCompanyGroupValue(id,'name');
        var companyGroupType = this.GetCompanyGroupValue(id,'companyGroupType');
        var txt = this.GetCompanyGroupTypeText(companyGroupType);

        return name + ' ('+txt+')';
      }

  }
};

</script>