<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddPartnerDiscount"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-select :label="$t('PARTNERDISCOUNT.ADD.LABEL.COMPANY_GRP')" v-model="data.companygroup_fk" class="ml-2" :danger="companygroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in companyGroups" />
            </vs-select>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-select :label="$t('PARTNERDISCOUNT.ADD.LABEL.PRODUCT_GRP')" v-model="data.productgroup_fk" class="ml-2" :danger="productgroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in productgroups" />
            </vs-select>
          </div>          
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.partner_discount_value" min="0" step="0.5" max="80" :label="$t('PARTNERDISCOUNT.ADD.LABEL.DISCOUNT')" class="inputx" />
            </div>
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.amountGreater" min="0" step="1" :label="$t('PARTNERDISCOUNT.ADD.LABEL.AMOUNT')" class="inputx" />
            </div>
          </div>   
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddPartnerDiscountDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.TITLE'),
      companygroup_fk_fail:false,
      companygroup_fk_ok:false,
      productgroup_fk_fail:false,
      productgroup_fk_ok:false,
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('partnerdiscount/savePartnerdiscount', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERDISCOUNT.ADD_PARTNERDISCOUNT.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          companygroup_fk:-1,
          productgroup_fk:-1,
          partner_discount_value:10,
          company_fk:0,
          amountGreater:0
        };
      }
  },
  computed:{
    formValid(){
      return true;
    },
    productgroups(){
      return this.GetListOfProductgroupsForSelect();
    },
    companyGroups(){
      return this.GetCompanyGroupsForSelect();
    }
  },
  watch:{
  }
};
</script>
<style>
div#promptAddPartnerDiscount > div.vs-dialog{
  max-width: 500px !important;
}
div#promptAddPartnerDiscount > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>